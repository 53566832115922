import service from "./service-base";

export default {
    getByCode: (model) => {
        return service.post(`/requirement/getbycode`, model);
    },
    get: (id) => {
        return service.post(`/requirement/get`, {id});
    },
    getDeclaration: (requirementId,declarationId) => {
        return service.post(`/requirement/declaration/get`, {RequirementId:requirementId, DeclarationId:declarationId });
    },
    getDeclarationAnonymous: (requirementId,declarationId) => {
        return service.post(`/requirement/declaration/getanonymous`, {RequirementId:requirementId, DeclarationId:declarationId });
    },
    search: (pageNumber, sortBy, model) => {
        model.pageNumber = pageNumber
        model.sortBy = sortBy
         return service.post(`/requirement/search`, model);
    },
    create: (model) => {
        return service.post(`/requirement/create`, model);
    },
    gettypes: () => {
        return service.get(`/requirement/gettypes`);
    },
    validatecode: (code,requirementRequestId) => {
        return service.put(`/requirement/validatecode`,{AccessCode:code, RequirementRequestId:requirementRequestId });
    },
    resendemail: (code) => {
        return service.get(`/requirement/resendemail/${code}`);
    },
    getAttachment:(requirementId, attachmentId,accessCode)=>{
        return service.post(`/requirement/getattachment`,{
            requirementId,
            attachmentId,
            accessCode
        });
    },
    getprojectattachment:(requirementId, attachmentId,accessCode)=>{
        return service.post(`/requirement/getprojectattachment`,{
            requirementId,
            attachmentId,
            accessCode
        });
    },
    getdecisionattachment:(requirementId, attachmentId,accessCode)=>{
        return service.post(`/requirement/getdecisionattachment`,{
            requirementId,
            attachmentId,
            accessCode
        });
    },
    createpronunciation:(requirementId, pronunciation,attachments) => {
        return service.put(`/requirement/createpronunciation`, {
            requirementId:requirementId,
            pronunciation:pronunciation,
            attachments:attachments
        });
    },
    
};