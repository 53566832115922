<template>
  <div class="row mt-5">
    <div class="row mb-3">
      <div class="row">
        <div class="col-12 d-f">
          <span class="title">{{
            selectedLang.selectedDeclarationsResume
          }}</span>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-4">
        <CustomInput
          v-model="entity"
          :label="selectedLang.entityTxt"
          type="Text"
          :disabled="true"
        />
      </div>
      <div class="col-4">
        <CustomInput
          v-model="role"
          :label="selectedLang.officeTxt"
          type="Text"
          :disabled="true"
        />
      </div>
      <div class="col-4">
        <CustomInput
          v-model="holder"
          :label="selectedLang.titleName"
          type="Text"
          :disabled="true"
        />
      </div>
    </div>

    <h1>{{ selectedLang.selectedDeclarations }}</h1>
    <TableSort
      :showEmptyrow="true"
      defaultSort="Id"
      :checkBox="false"
      :loading="loading"
      :tableModel="tableModel"
      :actions="declarationListLocal.length > 1"
      :searchFunc="searchFunc"
      :sortFunc="sortFunc"
      :showPagination="false"
      :showTotal="false"
    >
      <tr v-for="(item, index) in declarationListLocal" :key="index">
        <td>{{ $filters.formatDate(item.startDate) }}</td>
        <td>{{ $filters.formatDate(item.endDate) }}</td>
        <td>
          {{ selectedLang.declarationNatureTypeEnum[item.natureTypeId] }}
        </td>
        <td v-if="declarationListLocal.length > 1">
          <span
            :title="selectedLang.deleteDeclarationTooltip"
            class="btnAlike"
            @click="deleteItemFromSelectedDeclarations(index)"
          >
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5 6.72884C18.17 6.38509 14.82 6.20801 11.48 6.20801C9.5 6.20801 7.52 6.31217 5.54 6.52051L3.5 6.72884"
                stroke="#102E40"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 5.67676L9.22 4.31217C9.38 3.32259 9.5 2.58301 11.19 2.58301L13.81 2.58301C15.5 2.58301 15.63 3.36426 15.78 4.32259L16 5.67676"
                stroke="#1B1B1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.3494 10.0205L18.6994 20.5101C18.5894 22.1455 18.4994 23.4163 15.7094 23.4163H9.28941C6.49941 23.4163 6.40941 22.1455 6.29941 20.5101L5.64941 10.0205"
                stroke="#1B1B1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.8301 17.6875H14.1601"
                stroke="#1B1B1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 13.5205H15"
                stroke="#1B1B1B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </td>
      </tr>
    </TableSort>

    <div class="mt-5">
      <div class="row">
        <div class="col-12 d-f">
          <span class="title">{{ selectedLang.form }}</span>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <CustomInput
            v-model="form.nome"
            type="Text"
            :label="selectedLang.completeName"
            :placeholder="null"
            :errorMsg="null"
            :disabled="showValidationCode"
            :required="true"
            :maxlength="150"
            :options="null"
            :searchable="false"
            :mode="null"
            :maxval="null"
            :showErrorMsg="!nomeIsValid && validateForm"
            :maxDate="null"
            :loading="false"
          />
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <CustomInput
            v-model="form.nif"
            type="Text"
            :label="selectedLang.nifEquivalent"
            :placeholder="null"
            :errorMsg="null"
            :disabled="showValidationCode"
            :required="true"
            :maxlength="25"
            :options="null"
            :searchable="false"
            :mode="null"
            :maxval="null"
            :showErrorMsg="!nifIsValid && validateForm"
            :maxDate="null"
            :loading="false"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-3">
          <CustomInput
            v-model="form.telemovel"
            type="Number"
            :label="selectedLang.mobilePhone"
            :placeholder="null"
            :errorMsg="null"
            :disabled="showValidationCode"
            :required="false"
            :maxlength="20"
            :options="null"
            :searchable="false"
            :mode="null"
            :maxval="null"
            :showErrorMsg="false"
            :maxDate="null"
            :loading="false"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-8 mb-3">
          <CustomInput
            v-model="form.email"
            type="Text"
            :label="selectedLang.electronicEmail"
            :placeholder="null"
            :disabled="showValidationCode"
            :required="true"
            :maxlength="100"
            :options="null"
            :searchable="false"
            :mode="null"
            :maxval="null"
            :showErrorMsg="!emailIsValid && validateForm"
            :maxDate="null"
            :loading="false"
            :errorMsg="selectedLang.invalidEmail"
          />
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-4 mb-3">
          <CustomInput
            v-model="form.fundamentacao"
            type="Multiselect"
            :label="selectedLang.fundamentationRequirement"
            :placeholder="null"
            :disabled="showValidationCode"
            :required="true"
            :maxlength="null"
            :options="fundamentacaoOptions"
            :searchable="false"
            :maxval="null"
            :showErrorMsg="!fundamentacaoIsValid && validateForm"
            :maxDate="null"
            :loading="false"
            :errorMsg="null"
          />
        </div> -->
        <div class="col-12 mb-3">
          <CustomInput
            v-model="form.descricao"
            type="Textarea"
            :label="selectedLang.requirementDescription"
            :placeholder="null"
            :required="true"
            :maxlength="500"
            :options="null"
            :searchable="false"
            :mode="null"
            :maxval="null"
            :showErrorMsg="!descricaoIsValid && validateForm"
            :maxDate="null"
            :loading="false"
            :errorMsg="null"
            :disabled="showValidationCode"
          />
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <h1>{{ selectedLang.justifyDocumentList }}</h1>
        <div class="col-12">
          <p style="text-align-last: left">
            Apenas são permitidos documentos do tipo pdf e word. O tamanho
            máximo de ficheiros permitido é 5 megabytes.
          </p>
        </div>
        <TableSort
          :showEmptyrow="false"
          defaultSort="Id"
          :checkBox="false"
          :loading="loading"
          :tableModel="tableModelDocument"
          searchFunc=""
          sortFunc=""
          :showPagination="false"
          :showTotal="false"
          :sortHeader="false"
          :actions="true"
        >
          <tr v-for="(item, index) in documents" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ utils.formatBytes(item.size) }}</td>
            <td>{{ utils.getFormatFileName(item.name) }}</td>
            <td class="d-f" v-if="!showValidationCode">
              <span @click="remove(item)" class="btnAlike">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8494 9.14001L18.1994 19.21C18.0894 20.78 17.9994 22 15.2094 22H8.78941C5.99941 22 5.90941 20.78 5.79941 19.21L5.14941 9.14001"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3301 16.5H13.6601"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </td>
          </tr>
          <tr v-if="!documentsIsValid && validateForm">
            <td colspan="6" class="tal-center">
              <span class="inputInvalidMsg">
                {{ selectedLang.makeRequestDocumentsRequiredMsg }}
              </span>
            </td>
          </tr>
          <tr v-if="!showValidationCode">
            <td colspan="6" class="p-0 pt-1">
              <span
                @click="newAttachment"
                class="d-f jc-center btnAlike"
                v-tooltip="
                  selectedLang.permitedFileType +
                  '.doc,.docx,.pdf' +
                  selectedLang.permitedFileSize
                "
              >
                <svg
                  style="margin: 9px 0px"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2241 7.57143V6.42857C12.2241 6.27381 12.1658 6.13988 12.0491 6.02678C11.9324 5.91369 11.7942 5.85714 11.6345 5.85714H9.27579V3.57143C9.27579 3.41667 9.21744 3.28274 9.10074 3.16964C8.98403 3.05655 8.84583 3 8.68613 3H7.50679C7.34709 3 7.20889 3.05655 7.09218 3.16964C6.97548 3.28274 6.91713 3.41667 6.91713 3.57143V5.85714H4.55847C4.39877 5.85714 4.26056 5.91369 4.14386 6.02678C4.02715 6.13988 3.9688 6.27381 3.9688 6.42857V7.57143C3.9688 7.72619 4.02715 7.86012 4.14386 7.97322C4.26056 8.08631 4.39877 8.14286 4.55847 8.14286H6.91713V10.4286C6.91713 10.5833 6.97548 10.7173 7.09218 10.8304C7.20889 10.9435 7.34709 11 7.50679 11H8.68613C8.84583 11 8.98403 10.9435 9.10074 10.8304C9.21744 10.7173 9.27579 10.5833 9.27579 10.4286V8.14286H11.6345C11.7942 8.14286 11.9324 8.08631 12.0491 7.97322C12.1658 7.86012 12.2241 7.72619 12.2241 7.57143ZM14.3511 3.49089C14.9969 4.56033 15.3199 5.73004 15.3199 7C15.3199 8.26996 14.9969 9.44119 14.3511 10.5137C13.7052 11.5862 12.829 12.4353 11.7223 13.0612C10.6156 13.6871 9.40695 14 8.09645 14C6.78596 14 5.57735 13.6871 4.47064 13.0612C3.36393 12.4353 2.48765 11.5862 1.84181 10.5137C1.19597 9.44119 0.873047 8.26996 0.873047 7C0.873047 5.73004 1.19597 4.55881 1.84181 3.48633C2.48765 2.41385 3.36393 1.56467 4.47064 0.938802C5.57735 0.312934 6.78596 0 8.09645 0C9.40695 0 10.6156 0.312934 11.7223 0.938802C12.829 1.56467 13.7052 2.41536 14.3511 3.49089Z"
                    fill="#007AC4"
                  />
                </svg>
                <p class="declarationRow">
                  {{ selectedLang.adding }}
                </p>
              </span>
              <input
                id="fileUpload"
                type="file"
                ref="fileUploadRef"
                accept=".doc,.docx,.pdf"
                @change="onChange($event)"
              />
            </td>
          </tr>
        </TableSort>
      </div>
    </div>

    <div
      style="text-align-last: left; text-align: justify"
      class="row mb-5 mt-4"
    >
      <div class="col-12">
        <hr class="rounded" />
      </div>
      <div class="col-12">
        <p>
          A sua identificação constitui condição legalmente necessária para a
          consulta da informação a que pretende aceder. O uso indevido dessa
          informação pode ser punido, nos termos da lei. A Entidade para a
          Transparência conservará os seus dados pessoais pelo prazo e nos
          termos que constam da informação disponível
          <a
            href="https://www.tribunalconstitucional.pt/tc/ept/infolegal.html"
            target="_blank"
            >aqui</a
          >.
        </p>
      </div>
    </div>
    <div class="row mb-3" v-if="!isComision && !showValidationCode">
      <div class="col-12 d-f jc-right">
        <RecaptchaV2 v-model="form.recaptchaToken" />
      </div>
    </div>
    <div class="row" v-if="!showValidationCode">
      <div class="col-12 d-f jc-right">
        <button
          :disabled="loading"
          type="button"
          @click="submit"
          class="btn btnS btnPrimary mr-1"
        >
          {{ selectedLang.sendRequest }}
        </button>
        <button
          type="button"
          @click="cancel"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.cancel }}
        </button>
      </div>
    </div>

    <div class="row mb-5" v-if="showValidationCode">
      <div class="col-6">
        <CustomInput
          v-model="validationCode"
          type="Text"
          :label="selectedLang.verificationCode"
          :placeholder="null"
          :disabled="false"
          :required="true"
          :maxlength="500"
          :options="null"
          :searchable="false"
          :mode="null"
          :maxval="null"
          :showErrorMsg="
            (!validationCodeIsValid && validateFormValidationCode) ||
            invalidVerificationCode
          "
          :maxDate="null"
          :loading="false"
          :errorMsg="selectedLang.invalidVerificationCode"
          @input="resetInvalidVerificationCode"
        />
      </div>
      <div
        class="col-6 d-f jc-right as-end"
        :class="{
          errorMargin:
            (!validationCodeIsValid && validateFormValidationCode) ||
            invalidVerificationCode,
        }"
      >
        <button
          type="button"
          @click="submitCode"
          class="btn btnS btnPrimary mr-1"
        >
          {{ selectedLang.makeRequest }}
        </button>

        <button
          @click="resendemail"
          type="button"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.resend }}
        </button>
        <button
          type="button"
          @click="cancel"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.cancel }}
        </button>
      </div>
    </div>
  </div>
  <!-- <Recaptcha /> -->
</template>

<script setup>
import { defineProps, ref, getCurrentInstance, computed, onMounted } from "vue";
import { utils, authentication, enums } from "@/helpers";
import {
  attachmentService,
  requirementService,
  comissionService,
} from "@/services";

var props = defineProps({
  declarationList: Array,
});

const { appContext } = getCurrentInstance();
const fileUploadRef = ref(null);
const emitter = appContext.config.globalProperties.emitter;

//const emit = defineEmits(["update:declarationList"]);

var selectedLang = appContext.config.globalProperties.selectedLang;

var declarationListLocal = ref([]);
var declarationList = ref(props.declarationList);
var fileData = ref(null);
var form = ref({
  nome: null,
  nif: null,
  telemovel: null,
  email: null,
  fundamentacao: 0,
  descricao: null,
  anexosIdList: [],
  recaptchaToken: null,
});
//var fundamentacaoOptions = ref([]);
var documents = ref([]);
var loading = ref(false);
var validateForm = ref(false);
var validationCode = ref(null);
var showValidationCode = ref(false);
var validateFormValidationCode = ref(false);
var requirementResponseId = ref("");
var invalidVerificationCode = ref(false);
var isComision = ref(false);
var requirementRequestId = ref(null);

onMounted(() => {
  declarationList.value.forEach((declaration) => {
    if (!declaration.natureTypeId)
      declaration.natureTypeId = declaration.natureType;
    declarationListLocal.value.push(declaration);
  });

  //gettypes();

  var auth = authentication.get();
  if (auth == null || auth.expired) {
    isComision.value = false;
  } else {
    isComision.value =
      auth.profiles[0] == enums.userProfileEnum.comission &&
      window.location.pathname.indexOf("comissaoparlamentar/titular/") != -1;
  }
});

var tableModel = computed(() => {
  return {
    headers: [
      {
        title: selectedLang.roleInicialDate,
        sort: "StartDate",
      },
      {
        title: selectedLang.roleFinalDate,
        sort: "EndDate",
      },
      {
        title: selectedLang.declarationNature,
        sort: "NatureTypeId",
      },
    ],
    total: declarationListLocal.value.length,
    pages: 0,
  };
});
var entity = computed(() => {
  if (props.declarationList.length == 0) {
    return "";
  } else {
    return props.declarationList[0].entity;
  }
});
var holder = computed(() => {
  if (props.declarationList.length == 0) {
    return "";
  } else {
    return props.declarationList[0].holder;
  }
});
var role = computed(() => {
  if (props.declarationList.length == 0) {
    return "";
  } else {
    return props.declarationList[0].role;
  }
});
var tableModelDocument = computed(() => {
  return {
    headers: [
      {
        title: selectedLang.fileName,
        sort: "fileName",
      },
      {
        title: selectedLang.size,
        sort: "size",
      },
      {
        title: selectedLang.format,
        sort: "format",
      },
    ],
    total: documents.value.length,
    pages: 0,
  };
});

//validations
var nomeIsValid = computed(() => {
  return !!form.value.nome;
});
var nifIsValid = computed(() => {
  return !!form.value.nif;
});
var emailIsValid = computed(() => {
  return utils.isValidEmail(form.value.email);
});
// var fundamentacaoIsValid = computed(() => {
//   return form.value.fundamentacao != null;
// });
var descricaoIsValid = computed(() => {
  return !!form.value.descricao;
});
var recaptchaTokenIsValid = computed(() => {
  return isComision.value || !!form.value.recaptchaToken;
});
var documentsIsValid = computed(() => {
  return documents.value.length > 0;
});
var formIsValid = computed(() => {
  return (
    nomeIsValid.value &&
    nifIsValid.value &&
    emailIsValid.value &&
    // fundamentacaoIsValid.value &&
    descricaoIsValid.value &&
    recaptchaTokenIsValid.value &&
    documentsIsValid.value
  );
});

var validationCodeIsValid = computed(() => {
  return !!validationCode.value;
});

function searchFunc() {}

function sortFunc() {}

function deleteItemFromSelectedDeclarations(index) {
  declarationListLocal.value.splice(index, 1);
}

function cancel() {
  emitter.emit("closeCustomModal", "pedidoRequerimentoModal");
}

async function newAttachment() {
  fileUploadRef.value.click();
}
async function onChange(event) {
  const file = event.target.files[0];
  const name = file.name;
  const format = utils.getFormatFileName(file.name);
  const mime = !file.type ? "application/octet-stream" : file.type;

  if (!utils.permittedMimesRquerimento(mime)) {
    utils.warningToast(selectedLang.invalidFileFormatForCodeValidationWordPdf);
    return;
  }
  if (!utils.permittedSize(file.size)) {
    utils.warningToast(selectedLang.invalidFileSize);
    return;
  }

  fileData.value = new Blob([file]);

  var promise = new Promise(getBuffer);

  promise
    .then(async (bytes) => {
      var base64 = utils.arrayBytesToBase64(bytes);
      documents.value.push({
        name: name,
        size: file.size,
        mime: mime,
        format: format,
        //data: Array.from(bytes),
        data: base64,
      });

      event.target.value = null;
    })
    .catch((err) => {
      utils.errorToast(selectedLang.newOppositionRequest, err);
    });
}
async function getBuffer(resolve) {
  var reader = new FileReader();
  reader.readAsArrayBuffer(fileData.value);
  reader.onloadend = function (e) {
    var bytes = new Uint8Array(e.target.result);
    resolve(bytes);
  };
  reader.onerror = function (e) {
    utils.errorToast(selectedLang.newOppositionRequest, e.target.error);
  };
}
async function remove(item) {
  utils.removeItemOfArray(documents.value, item);
}

async function saveDocuments() {
  loading.value = true;
  form.value.anexosIdList = [];
  let index = 1;
  documents.value.forEach(async (file) => {
    let response = await attachmentService.addAnonymous(file);

    if (response.code == 0) {
      form.value.anexosIdList.push(response.data.id);
    } else {
      utils.errorToast(
        selectedLang.newOppositionRequest,
        selectedLang.newDeclarationOppositionRequestUploadError
      );
    }

    if (index == documents.value.length) {
      if (documents.value.length == form.value.anexosIdList.length) {
        submitModel();
      } else {
        loading.value = false;
      }
    }

    index++;
  });
}

// async function gettypes() {
//   let response = await requirementService.gettypes();
//   if (response.code == 0) {
//     fundamentacaoOptions.value = response.data;
//   } else {
//     utils.errorToast(selectedLang.makeRequest, selectedLang.makeRequestError);
//   }
// }

async function submitModel() {
  let declarationIdList = [];
  declarationListLocal.value.forEach((element) => {
    let id = element.id ? element.id : element.draftId;

    declarationIdList.push(id);
  });

  let model = {
    Name: form.value.nome,
    NIF: form.value.nif,
    Email: form.value.email,
    Phone: form.value.telemovel,
    RequirementTypeId: null,
    Description: form.value.descricao,
    Attachments: form.value.anexosIdList,
    recaptchaToken: form.value.recaptchaToken,
    DeclarationIdList: declarationIdList,
  };

  let response = null;
  let auth = authentication.get();

  if (
    auth == null ||
    auth.expired ||
    window.location.pathname.indexOf("comissaoparlamentar/titular/") == -1
  ) {
    response = await requirementService.create(model);
  } else {
    response = await comissionService.createRequirements(model);
  }
  requirementRequestId.value = response.data.id;

  utils.validateResponse(response, null, () => {
    showValidationCode.value = true;
    validateFormValidationCode.value = false;
    requirementResponseId.value = response.data.id;
    utils.successToast(selectedLang.codeAccessEmailSended);
  });
}
async function submit() {
  validateForm.value = true;
  loading.value = true;

  if (formIsValid.value) {
    await saveDocuments();
  }
  loading.value = false;
}

async function submitCode() {
  validateFormValidationCode.value = true;

  if (validationCodeIsValid.value) {
    let response = await requirementService.validatecode(
      validationCode.value,
      requirementRequestId.value
    );
    if (response.code == 0) {
      utils.successToast(
        `${selectedLang.makeRequestSucessPt1}${requirementResponseId.value}${selectedLang.makeRequestSucessPt2}`
      );

      cancel();
    } else {
      invalidVerificationCode.value = true;
    }
  }
}

async function resendemail() {
  let response = await requirementService.resendemail(
    requirementResponseId.value
  );
  if (response.code == 0) {
    utils.successToast(selectedLang.emailResendSucssess);
  } else {
    utils.errorToast(selectedLang.emailResendError);
  }
}

function resetInvalidVerificationCode() {
  invalidVerificationCode.value = false;
}
</script>

<style scoped>
.errorMargin {
  margin-bottom: 17.25px;
}
</style>
