import globals from "@/globals";
import toaster from "@/components/toaster.vue";
import { useToast } from "vue-toastification";
import resources from "@/resources/index";
import moment from "moment";
import CryptoJS from "crypto-js";
//import moment from 'moment';
//import security from '@/helpers/security';
//import VueJwtDecode from "vue-jwt-decode";

const SECRET_KEY = "P@ssw0rd123";

export default {
  arrayToString(list) {
    var array = list.map((item) => item.name);
    return array.join(", ");
  },
  removeOfArrayById(list, id) {
    var result = list.filter((item) => {
      return item.id == id;
    });
    var index = list.indexOf(result[0]);
    list.splice(index, 1);
  },
  removeOfArrayByName(list, name) {
    var result = list.filter((item) => {
      return item.name == name;
    });
    var index = list.indexOf(result[0]);
    list.splice(index, 1);
  },
  removeOfArrayByKey(list, key) {
    var result = list.filter((item) => {
      return item.key == key;
    });
    var index = list.indexOf(result[0]);
    list.splice(index, 1);
  },
  removeOfArrayByLabel(list, label) {
    var result = list.filter((item) => {
      return item.label == label;
    });
    var index = list.indexOf(result[0]);
    list.splice(index, 1);
  },
  removeOfArrayByLabelAndName(list, label, name) {
    var result = list.filter((item) => {
      return item.label == label && item.name == name;
    });
    var index = list.indexOf(result[0]);
    list.splice(index, 1);
  },
  removeItemOfArray(list, item) {
    var index = list.indexOf(item);
    list.splice(index, 1);
  },
  addItemToArray(list, item) {
    var result = list.filter((i) => {
      return i.label == item.label && i.property == item.property;
    });

    if (result == 0) {
      list.push(item);
    }
  },
  setUrlRedirect(url) {
    if (url) {
      if (url.substr(0, 1) != "#") url = "/#" + url;

      localStorage.setItem(globals.URLREDIRECT, url);
    }
  },
  getUrlRedirect() {
    return localStorage.getItem(globals.URLREDIRECT);
  },
  removeUrlRedirect() {
    return localStorage.removeItem(globals.URLREDIRECT);
  },
  parseParms(hash) {
    var pieces = hash.split("&"),
      data = {},
      i,
      parts;
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split("=");
      if (parts.length < 2) {
        parts.push("");
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  },
  errorToast(message) {
    this.toast("e", null, message);
  },
  successToast(message) {
    this.toast("s", null, message);
  },
  infoToast(message) {
    this.toast("i", null, message);
  },
  warningToast(message) {
    this.toast("w", null, message);
  },
  toast(type, title, message) {
    const toast = useToast();
    const options = {
      position: "top-center",
      timeout: 5000,
      closeOnClick: false,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    };

    const component = {
      component: toaster,
      props: { toastTitle: title, toastText: message },
    };

    switch (type.toLowerCase()) {
      case "e":
        toast.error(component, options);
        break;
      case "i":
        toast.info(component, options);
        break;
      case "w":
        toast.warning(component, options);
        break;
      case "s":
        toast.success(component, options);
        break;
      default:
        toast(component, options);
    }
  },
  arrayBytesToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  },
  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },
  getFormatFileName(name) {
    if (!name) return "";
    const parts = name.split(".");
    if (parts.length) {
      return `.${parts[parts.length - 1]}`;
    } else {
      return "";
    }
  },
  downloadFile(attachment) {
    const linkSource = `data:${attachment.mime};base64,${attachment.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = attachment.name;
    downloadLink.click();
  },
  permittedMimes(mime) {
    const mimes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-word",
      "application/pdf",
      "application/xls",
      "application/xlsx",
      "application/doc",
      "application/docx",
    ];

    var result = mimes.filter((item) => {
      return item == mime;
    });
    return result.length;
  },
  permittedMimesRquerimento(mime) {
    const mimes = [
      "application/pdf",
      "application/doc",
      "application/docx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    var result = mimes.filter((item) => {
      return item == mime;
    });
    return result.length;
  },
  permittedSize(bytes, size = 5000000 ) {
    return bytes <= size
  },
  isValidEmail(email) {
    var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isValidPassword(password) {
    if (this.isNullOrEmpty(password)) {
      return false
    }
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,40}$/.test(
        password
      );
  },
  sortArrayOffline(sortBy, items) {
    items.sort(function (a, b) {
      const parts = sortBy.split(" ");
      const field = parts[0];
      let order = "";

      if (parts.length == 2) {
        order = parts[1].toLowerCase();
      }

      let valueA = a[field];
      let valueB = b[field];

      valueA = validateValue(valueA);
      valueB = validateValue(valueB);

      var sortMethod = -1;
      if (order == "asc") {
        sortMethod = 1;
      }

      if (isNumber(valueA)) {
        if (order == "asc") {
          return valueB - valueA;
        } else {
          return valueA - valueB;
        }
      } else {
        if (valueA < valueB) {
          return -1 * sortMethod;
        }

        if (valueA > valueB) {
          return 1 * sortMethod;
        }
        return 0;
      }
    });

    function validateValue(value) {
      if (value == null) return "";

      const len = value.length;
      //2022-12-31T00:00:00
      if (typeof value == "number") {
        return value;
      } else if (
        typeof value == "string" &&
        len == 19 &&
        value.indexOf("T") == 10
      ) {
        return new Date(value);
      } else if (typeof value == "string") {
        return value.toString().toLowerCase();
      } else {
        return "";
      }
    }

    function isNumber(val) {
      return typeof val === "number";
    }
  },
  truncate(value, len) {
    if (!value) return value;

    var aux = value.toString();

    if (aux.length > len) return aux.substr(0, len);

    return value.trim();
  },
  validateInputDeclaration(element) {
    const lang = resources.selectLang("pt");
    if (!element.isVisible) {
      element.value = lang.decrarationRestrictReasonEnum[element.reason];
    }
    return element;
  },

  validateResponse(response, sucessMsg, callback) {
    if (response.code == 0) {
      if (sucessMsg) this.successToast(sucessMsg);

      callback();
    } else {
      let selectedLang = resources.selectLang("pt");
      let errorMsg = selectedLang.errorCode[response.code];

      if (errorMsg) this.errorToast(errorMsg);
      else this.errorToast(`${selectedLang.genericErrorMsg} (Erro: [${response.code}])`);
    }
  },
  
  isNullOrEmpty(value) {
    if (value === null || value === undefined) return true;
  
    // Verifica se é uma string vazia ou apenas espaços
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
  
    // Verifica se é uma data válida
    if (value instanceof Date && !isNaN(value)) {
      return false; // Retorna false se for uma data válida
    }
    if (value instanceof Date && isNaN(value)) {
      return true; // Retorna true se for uma data inválida
    }
    // Verifica se é um array vazio
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  
    // Verifica se é um objeto vazio
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
  
    if (value === "0,00 €" || value === "0.00 %") {
      return true;
    }
  
    return false;
  },
  
  parseToInt(str) {
    var parsedInt = parseInt(str);
    if (isNaN(parsedInt)) {
      return null;
    } else {
      return parsedInt;
    }
  },
  checkUserProfile(user, profile){
    if (user == null || user.expired) {
      return false
    }
    return user.profiles[0] == profile
  },
  // checkNif(nif) {
  //   if (nif == null || nif == "") {
  //     return false
  //   }
  //   var nifPattern = /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/;
  //   return nifPattern.test(nif);
  // },

  checkNif(nif) {
    // Remover espaços em branco
    nif = nif.replace(/\s/g, '');
  
    // Verificar se é uma string de 9 dígitos
    if (!/^\d{9}$/.test(nif)) {
      return false;
    }
  
    const checkDigit = parseInt(nif[8]);
  
    // Validar diferentes padrões de NIF
    const regexArray = [
      /^[1-9]\d{8}$/,          // Pessoas Singulares
      /^[1-9]\d{7}[-]\d{1}$/,  // Pessoas Singulares (com hífen)
      /^[1-9]\d{6}[-]\d{2}[0-9A-HJ-NP-TV-Z]$/,  // Pessoas Coletivas
      /^[1-9]\d{6}[-]\d{2}[0-9A-HJ-NP-TV-Z][-]\d{1}$/  // Pessoas Coletivas (com segundo hífen)
    ];
  
    // Verificar se o NIF corresponde a algum dos padrões
    const isValidPattern = regexArray.some(regex => regex.test(nif));
  
    if (!isValidPattern) {
      return false;
    }
  
    // Calcular dígito de controlo
    const digits = nif.substring(0, 8).split('').map(Number);
    const weights = [9, 8, 7, 6, 5, 4, 3, 2];
    const weightedSum = digits.reduce((sum, digit, index) => sum + digit * weights[index], 0);
    const remainder = weightedSum % 11;
    const expectedCheckDigit = remainder < 2 ? 0 : 11 - remainder;
  
    // Verificar se o dígito de controlo coincide
    return checkDigit === expectedCheckDigit;
  },

  checkIfGUIDExistsInArray(array, guid, index) {
    if (array == null)  return
    if (array[index] == null) {
      array[index] = {
        key: `${guid}-col${index + 1}`,
        value: null,
        isVisible: true,
      }
      
    }
  },
  checkAllIfGUIDExistsInArray(array, guid, indexs) {
    indexs.forEach(index => {
      this.checkIfGUIDExistsInArray(array, guid, index)
    });
    
  },
  
  formatDate(date, format = "DD-MM-YYYY ") {
    if (!date || date === "") {
      return "";
    }

    if (typeof date === "string") {
      date = new Date(date);
      date = moment(String(date));
    } else{
      date = moment(String(date))
    }
    return date.format(format);
  },

  // Função para criptografar dados
  encryptData(data) {
    if (typeof data != "string")
      data = data.toString()
    if (data) {  
      const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY);
      return this.toBase64(encrypted.toString());
    }
  },
  
  // Função para descriptografar dados
  decryptData(data) {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(this.fromBase64(data), SECRET_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
  },
 
  normalizeString(str) {
    return str
      .trim() // Remove espaços em branco no início e no fim
      .toLowerCase() // Converte para minúsculas
      .normalize('NFD') // Normaliza a string para decompor caracteres acentuados
      .replace(/[\u0300-\u036f]/g, ''); // Remove os caracteres de acentuação
  },

  // Função para codificar uma string em Base64
 toBase64(data) {
  if (typeof data !== "string") {
    data = data.toString();
  }
  return btoa(unescape(encodeURIComponent(data))); // Codifica em Base64
},

// Função para decodificar uma string Base64
 fromBase64(base64String) {
  return decodeURIComponent(escape(atob(base64String))); // Decodifica de Base64 para texto original
}

};
