<template>
  <form>
    <div class="row mb-5 mt-2">
      <div class="col-12 info_box">
        <p class="m-3">
          {{ selectedLang.homePageWarningTxt }}
        </p>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <h1 class="">
          {{ selectedLang.searchTxt }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          :label="selectedLang.entity"
          type="Multiselect"
          v-model="entityValue"
          :options="entityOptions"
          :searchable="true"
          :placeholder="selectedLang.entityInsertPrompt"
          :noResultsText="selectedLang.noResultsText"
          :multipleLabel="multipleLabel"
          :close-on-select="true"
          @select="selectEntityHandler"
          @clear="clearEntityMultiSelect"
          @search-change="entitySearchChange"
          :showOptions="showEntityOptions"
          :loading="entityOptionsLoading"
        />
      </div>
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          :label="selectedLang.officeTxt"
          type="Multiselect"
          v-model="roleValue"
          :options="roleOptions"
          :searchable="true"
          :placeholder="selectedLang.officePrompt"
          :noResultsText="selectedLang.noResultsText"
          :multipleLabel="multipleLabel"
          :close-on-select="true"
          @select="selectRoleHandler"
          @clear="clearRoleMultiSelect"
          @search-change="roleSearchChange"
          :showOptions="showRoleOptions"
          :loading="roleOptionsLoading"
          :disabled="!showRoleMultiSelect"
        />
      </div>
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          :label="selectedLang.titleName"
          type="Multiselect"
          :create-option="true"
          :on-create="handleTagCreate"
          :disabled="false"
          v-model="holderValue"
          :options="holderOptions"
          :searchable="true"
          :placeholder="selectedLang.titlePrompt"
          :noResultsText="selectedLang.noResultsText"
          :multipleLabel="multipleLabel"
          :close-on-select="true"
          @select="selectHolderHandler"
          @clear="clearHolderMultiSelect"
          @search-change="holderSearchChange"
          :showOptions="showHolderOptions"
          :loading="roleOptionsLoading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-f jc-right">
        <button
          type="button"
          @click="clearForm"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.clear }}
        </button>
        <button
          type="button"
          @click="apply"
          class="btn btnS btnPrimary"
          :disabled="!enableApply"
        >
          {{ selectedLang.apply }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import publicQueryService from "@/services/publicQuery.service";

export default {
  component: {},
  data() {
    return {
      entityValue: {},
      roleValue: {},
      holderValue: {},
      entityOptions: [],
      roleOptions: [],
      holderOptions: [],
      entitySearchValue: "",
      roleSearchValue: "",
      holderSearchValue: "",
      entityOptionsLoading: false,
      roleOptionsLoading: false,
      holderOptionsLoading: false,
    };
  },
  methods: {
    clearEntityMultiSelect() {
      this.entityValue = {};
      this.roleValue = {};
      this.holderValue = {};
      this.getPublicInfo();
    },
    clearRoleMultiSelect() {
      this.roleValue = {};
      this.holderValue = {};
      this.getPublicInfo();
    },
    clearHolderMultiSelect() {
      this.holderValue = {};
      this.getPublicInfo();
    },
    clearForm() {
      this.entityValue = {};
      this.roleValue = {};
      this.holderValue = {};

      this.getPublicInfo();
      this.emitter.emit("HomeHideTable");
    },

    apply() {
      let holderId = null;
      let HolderName = "";

      console.log("this.holderValue", this.holderValue);

      if (isNaN(this.holderValue)) {
        HolderName = this.holderValue;
      } else {
        var holder = this.holderOptions.find(
          (obj) => obj.value === this.holderValue
        );
        HolderName = holder?.label;
        //holderId = this.holderValue;
      }
      // if (this.holderValue >= 0) {
      //   holderId = this.holderValue;
      // } else {
      //   debugger;
      //   this.holderOptions.forEach((option) => {
      //     if (option.value == this.holderValue) HolderName = option.label;
      //   });
      // }

      let emitter = {
        entityId: this.entityValue,
        roleId: this.roleValue,
        holderId: holderId,
        HolderName: HolderName,
      };
      this.emitter.emit("HomeSearch", emitter);
    },
    multipleLabel(value) {
      var txt =
        value.length > 1
          ? this.selectedLang.optionsSelectedTxt
          : this.selectedLang.optionSelectedTxt;
      return `${value.length} ${txt}`;
    },
    selectEntityHandler() {
      if (!Number.isInteger(this.roleValue)) this.roleValue = null;
      if (!Number.isInteger(this.holderValue)) this.holderValue = null;
      this.getRoles({ EntityId: this.entityValue, HolderId: this.holderValue });
      this.getHolders({ EntityId: this.entityValue, RoleId: this.roleValue });

      //this.getPublicInfo();
    },
    selectRoleHandler() {
      if (!Number.isInteger(this.holderValue)) this.holderValue = null;
      this.getHolders({ EntityId: this.entityValue, RoleId: this.roleValue });

      //this.getPublicInfo();
    },
    selectHolderHandler(value) {
      this.holderValue = value;
      //this.getPublicInfo();
    },
    getPublicInfo() {
      if (!Number.isInteger(this.entityValue)) this.entityValue = null;
      if (!Number.isInteger(this.roleValue)) this.roleValue = null;
      if (!Number.isInteger(this.holderValue)) this.holderValue = null;

      this.getEntities({ RoleId: this.roleValue, HolderId: this.holderValue });
      this.getRoles({ EntityId: this.entityValue, HolderId: this.holderValue });
      this.getHolders({ EntityId: this.entityValue, RoleId: this.roleValue });
    },

    getEntities(filter) {
      this.entityOptionsLoading = true;
      publicQueryService
        .getAllEntities(filter)
        .then((response) => {
          if (response.code == 0) this.entityOptions = response.data;
        })
        .finally(() => {
          this.entityOptionsLoading = false;
        });
    },
    getRoles(filter) {
      this.roleOptionsLoading = true;
      publicQueryService
        .getAllRoles(filter)
        .then((response) => {
          if (response.code == 0) this.roleOptions = response.data;
        })
        .finally(() => {
          this.roleOptionsLoading = false;
        });
    },
    getHolders(filter) {
      this.holderOptionsLoading = true;
      publicQueryService
        .getAllHolders(filter)
        .then((response) => {
          if (response.code == 0) this.holderOptions = response.data;
        })
        .finally(() => {
          this.holderOptionsLoading = false;
        });
    },
    handleTagCreate(option) {
      option.value = option.label;
      this.holderOptions.push(option);
      return option;
    },
    entitySearchChange(query) {
      this.entitySearchValue = query;
    },
    roleSearchChange(query) {
      this.roleSearchValue = query;
    },
    holderSearchChange(query) {
      this.holderSearchValue = query;
    },
  },
  mounted() {
    this.getPublicInfo();
  },
  computed: {
    enableApply() {
      //return this.entityValue && this.roleValue && this.holderValue;
      return this.entityValue || this.holderValue;
    },
    showRoleMultiSelect() {
      return !!this.entityValue;
    },
    showHolderMultiSelect() {
      return !!this.entityValue && !!this.roleValue;
    },
    showEntityOptions() {
      return !!this.entitySearchValue;
    },
    showRoleOptions() {
      return !!this.roleSearchValue;
    },
    showHolderOptions() {
      return !!this.holderSearchValue;
    },
  },
};
</script>
